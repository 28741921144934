import React, { useState, useEffect } from 'react';
import '../estilo/Contenedor.css';
import imagen1 from '../imagenes/imagen 1.png';
import imagen2 from '../imagenes/imagen 2.png';
import imagen3 from '../imagenes/imagen 3.png';
import '@fortawesome/fontawesome-free/css/all.min.css';

function Contenedor() {
  const [image, setImage] = useState(0);
  const images = [
    {
      path: imagen1,
      alt: 'Imagen 1'
    },
    {
      path: imagen2,
      alt: 'Imagen 2'
    },
    {
      path: imagen3,
      alt: 'Imagen 3'
    }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setImage((image + 1) % images.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [image, images.length]);

  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="contenedor">
      <div className="contenedortextos">
        <h1 className='Titles'>Guanacaste fly fishing</h1>
        <p className="textos">
          Guanacaste Fly Fishing is a company with more than 15 years of experience, 
          founded by Esteban Blanco, owner and adventour local fisherman who wanted to 
          take fishing to other levels in the area. 
          {expanded && (
            <span>
              Esteban get the IGFA world record by exploring rivers and lagoons 
              in inhospitable places of Costa Rica, Esteban Blanco is the one that 
              organize all your fly fishing trips, you can achieve unique experiences 
              in fly fishing in Costa rica (we cover all Costa rica). Our company is based in fishing
               in the northern jungle of Guanacaste of Costa Rica we include all the amenities 
               in terms of equipment and boats specialized for fly fishing, 
               We are characterized by the service and comfort of the client as well as in the 
               search of species specified. We have 4 fishing guides and 3 drivers at your 
               disposal. We consider ourselves a fly fishing team with whom, in addition to 
               spending a good and comfortable day of fishing, you will enjoy the maximum.
            </span>
          )}
        </p>
        <button className="toggle-button" onClick={handleToggle}>
          {expanded? (
            <i className="fas fa-chevron-up" />
          ) : (
            <i className="fas fa-chevron-down" />
          )}
        </button>
      </div>
      <div className='contendorimagenes'>
        <img className='imagenes' src={images[image].path} alt={images[image].alt}></img>
      </div>
    </div>
  );
}

export default Contenedor;
