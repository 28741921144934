import React, { useState, useEffect } from 'react';
import '../estilo/Contenedor.css';
import imagen1 from '../imagenes/tour3-3.jpg';
import imagen2 from '../imagenes/tour3-2.jpg';
import imagen3 from '../imagenes/tour3-1.jpg';
import imagen4 from '../imagenes/tour3-4.jpg';
import '@fortawesome/fontawesome-free/css/all.min.css';
function Contenedor1() {
  const [image, setImage] = useState(0);
  const images = [
    {
      path: imagen4,
      alt: 'Imagen 4'
    },
    {
      path: imagen1,
      alt: 'Imagen 1'
    },
    {
      path: imagen2,
      alt: 'Imagen 2'
    },
    {
      path: imagen3,
      alt: 'Imagen 3'
    }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setImage((image + 1) % images.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [image, images.length]);

  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="contenedor">
      <div className="contenedortextos">
        <h1 className="Titles">Salt Water Fishing Trip - (Rooster Land) </h1>
        <p className="textos">The Guanacaste Rooster Fly Fishing Tour is spectacular, it is easy to see, superficial fishing, surrounding islands or large sandbars on the beaches.
        {expanded && (
            <span>
              located between 1 hour and 1 hour and 45 minutes from the main hotel 
              destinations of the Guanacaste tour. We use equipment between 9w and 12w to
              do this beautiful fly fishing. The fishing is done from June to October, 
              the months where they are the perfect conditions to be able to carry it out. 
              Another important point is that between these same places beyond the beautiful 
              rooster fish we can fish tuna jacks, needle fish snapper and with very lucky 
              sailfish not far from the coast. In September and October we are surprised by 
              the beautiful humpback whales. All tours include drinks, food, transportation 
              and fish equipment.
            </span>
          )}
        </p>
        <button className="toggle-button" onClick={handleToggle}>
          {expanded? (
            <i className="fas fa-chevron-up" />
          ) : (
            <i className="fas fa-chevron-down" />
          )}
        </button>
        </div>
     <div className='contendorimagenes'>
        <img className='imagenes' src={images[image].path} alt={images[image].alt}></img>
     </div>
        
    </div>
  );
}

export default Contenedor1;