import React from 'react';
import '../estilo/Contenedor.css';
function Contenedor5() {

  return (
    <div className="contenedor">
      <div>
        <p className="Titles">Responsabilities of the company </p>
        <p className="textos">
        Welcome to Guanacaste fly-fishng tours to make your trip safe, we want to
        inform you about our safety policies and our company also require that you
        agree where is indicated below to the terms and condition prior to 
        participating in our tour or any that might be subcontracted to third parties.
         For the reason beyoond to our control (climate, river levels etc.) our 
         company will at our sole discretion cance any other tour with equal or 
         similaradventure appeal. In such case, our head guide makes the final 
         decision. Guanacaste Fly-Fishing also reserve the right to cancel any trip 
         ue to unsafe conditions and will make a tour according to stablished company 
         policies. Full refund is given if (on rare occasion) no tour is made. 
         The Fly-Fising tour also involves some inherit risk and the physical 
         exertion, so you must be in good physical condition without any recent 
         surgery, heart condition, pregnacy, disability, or injury. If you take 
         medication (e.g allergy oils) or have other medical concerns, please 
         inform the head guide. The natural enviroment of Costa rica is filled of
         insects, sankes, crocodiles, and variety of other wildlife. In addition, 
         landslides falling rocks and sudden flash floods and other natural phenomena 
         are possible in Costa rica so we may not hold resposability or an accountability for an
         encounter with any of the above and other acts of God. Guanacaste 
         Fly-Fishing, due to  the Costa Rican tropical weather recommens to all 
         participants of the tour to wear sunscreen, caps, or gats, sunscreen 
         clothing to be protected against the harmful effects of the sun and 
         high temperatures. Clients must no be uder influence of alcohol or 
         drugs, other wise the tour shall be cancelled, clients should be able 
         to swin. please do not take valuables on our trips such as jewelry, 
         money or cameras if clients bring them, they will be assuming all the 
         risk and will totally exempt the company. Guanacaste Fly-Fishing and 
         its affiliate are not resposible for the lost, broken or stolen items. 
         About the payments  if you reserve you only can pay throught paypal and 
         you will need to pay the half of the total ammount plus the paypal fees. 
         When the payment its already accredited the only way of making it effective
         is doing the trip on the day we according or we can change the date when we 
         are a available (we don't refund the money at any circunstances of the 
         customer). When we you decide to make the trip with us you will need to have 
         an international health and life insurance plan. And before the trip you
         will need to sing a waver form about the  politics of the company.
         </p>
        </div> 
    </div>
  );
}

export default Contenedor5;