import '../estilo/Contenedor4.css';
function Contenedor4() {

  return (
    <div className="contenedor4">
       <p className="Titles">  Fresh Water Casting and Spinning Fishing Trip (Tenorio|Tempisque|Corobici)  </p>
      <div className="contenedortexto4">
       
        <iframe className='video'
          src="https://www.youtube.com/embed/wuLeGX3vVr4"
          title="YouTube video Costarica Guanacste fly fishing"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen= "true"
        ></iframe>
        </div>
    </div>
  );
}

export default Contenedor4;