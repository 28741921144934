import React from "react";
import '../estilo/Contactus.css'
function Contactcus() {
   return( 

         <div className="RedesContenedor">
             <div className="ContenedorFlexiado">
            <img className="logoC" src={require("../imagenes/logo.png")} alt="logo"/>
            <p className="Titles"> Guanacaste, Costa Rica</p>
            <p className="Titles">Contact Information</p>
            <p className="Titles"> estebanfishingguide@gmail.com</p>
            <p className="Titles"> Tel: (+506) 8832-6644</p>
            <button className="Redes"  onClick={() => window.location.href='mailto:estebanfishingguide@gmail.com'}>Send email</button></div>
             <div>
            <p className="Titles"> ¡Visit Our Social Media pages! </p>
            <div className="ContenedorBotones">
            <button class="Redes"  onClick={() => window.location.href='https://wa.me/qr/KOYT77GTU2B5B1'}>WhatsApp</button>
            <button className="Redes"  onClick={() => window.location.href='https://www.youtube.com/@estebanblanco7234'}>Youtube</button>
            <button className="Redes"  onClick={() => window.location.href='https://www.facebook.com/people/Guanacaste-Fly-FIshing/100047762666339/'}>Facebook</button>
            <button className="Redes"  onClick={() => window.location.href='https://www.tiktok.com/@guanacasteflyfishing?_t=8j45Y9wGDS9&_r=1'}>Tiktok</button>
            <button className="Redes"  onClick={() => window.location.href='https://www.instagram.com/guanacastefly?igsh=emN5bmNtbjFlN296'}>Instagram</button>
            </div>
            </div>
        </div>
        );

}

export default Contactcus;