import './App.css';
import MenuBar from './componentes/menu_bar';
import Contenedor from './componentes/Contenedor';
import Contenedor1 from './componentes/Contenedor1';
import Contenedor2 from './componentes/Contenedor2';
import Contenedor3 from './componentes/Contenedor3';
import Contenedor4 from './componentes/Contenedor4';
import Contenedor5 from './componentes/Contenedor5';
import Contactus from './componentes/Contacus';
import Footer from './componentes/Footer';
function App() {
  return (
    <div className="App">
      <MenuBar/>
      <Contenedor/>
      <Contenedor1/>
      <Contenedor2/>
      <Contenedor3/>
      <Contenedor4/>
      <Contenedor5/>
      <Contactus/>
      <Footer/>
    </div>
  );
}

export default App;
