import React from 'react';
import '../estilo/Footer.css';

function Footer() {
  return (
    <div className="footer">
      <div className="izquierda">
        @2024 Guanacaste fly fishing | All Rights reserved
      </div>
      <div className="derecha">
        made by @2024 Bits-Labs
      </div>
    </div>
  );
}

export default Footer;
