import React, { useState, useEffect } from 'react';
import '../estilo/Contenedor.css';
import imagen1 from '../imagenes/tour2-1.jpg';
import imagen2 from '../imagenes/tour2-2.jpg';
import imagen3 from '../imagenes/tour2-3.jpg';
import '@fortawesome/fontawesome-free/css/all.min.css';
function Contenedor3() {
  const [image, setImage] = useState(0);
  const images = [
    {
      path: imagen1,
      alt: 'Imagen 1'
    },
    {
      path: imagen2,
      alt: 'Imagen 2'
    },
    {
      path: imagen3,
      alt: 'Imagen 3'
    }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setImage((image + 1) % images.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [image, images.length]);

  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="contenedor">
      <div className="contenedortextos">
        <p className="Titles"> Fresh Water Fishing Trip - (Corobici|Tenorio|Bebedero fly) (Machaca & snook)</p>
        <p className="textos">On this Tour we'll head to Guanacaste's lush river reeds in the Corobici river. This magical place of murky waters is where we'll find this fast and ferocious fish. 
        {expanded && (
            <span>
            Similar to trout placed in the currents or under the fruit trees, the Machaca is a 
            fish well known in the area for being a very voracious surface eater, feeding on 
            fruits and seasonal flowers. Once hooked, the Machaca puts up a fight and gives a 
            spectacular show by jumping out of the water. This makes the fish so much fun to 
            catch. This river is connected so close to the ocean so we can catch snapper and 
            snooks in the same time !! This tour is approximately 6 to 7 hours depending on 
            the water level of the river. Here we will use imitation flowers or fruit like 
            flies or lures to be released between strong currents or under trees and streamer 
            for snapper and snooks. we use 6 - 7 wt rods will be needed here with floating 
            line. This is a longer trip where we'll fish the morning and take a break at noon 
            for lunch by the river. After that we'll get back to fishing in the afternoon 
            where we have greater piques in fish. Our tours its includes the Transfer from 
            hotel ( Guanacaste area) all fishing tackle( flies , reel . rods), food and soft 
            drinks!
            </span>
          )}
        </p>
        <button className="toggle-button" onClick={handleToggle}>
          {expanded? (
            <i className="fas fa-chevron-up" />
          ) : (
            <i className="fas fa-chevron-down" />
          )}
        </button>
        </div>
     <div className='contendorimagenes'>
        <img className='imagenes' src={images[image].path} alt={images[image].alt}></img>
     </div>
        
    </div>
  );
}

export default Contenedor3;