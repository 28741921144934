import React, { useState, useEffect } from 'react';
import '../estilo/Contenedor.css';
import imagen1 from '../imagenes/tour1-3.jpg';
import imagen2 from '../imagenes/tour1-2.jpg';
import imagen3 from '../imagenes/tour1-1.jpg';
import imagen4 from '../imagenes/tour1-4.jpg';
import '@fortawesome/fontawesome-free/css/all.min.css';
function Contenedor2() {
  const [image, setImage] = useState(0);
  const images = [
    {
      path: imagen4,
      alt: 'Imagen 4'
    },
    {
      path: imagen1,
      alt: 'Imagen 1'
    },
    {
      path: imagen2,
      alt: 'Imagen 2'
    },
    {
      path: imagen3,
      alt: 'Imagen 3'
    }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setImage((image + 1) % images.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [image, images.length]);

  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="contenedor">
      <div className="contenedortextos">
        <p className="Titles">Tempisque river fly (Rainbow  & jaguar bass)</p>
        <p className="textos">Being one of the few places that retains a good deal of large Rainbow Bass, this is one of our exclusive and unique tours in our country. 
        {expanded && (
            <span>
              This trip takes place in the upper parts of the Tempisque river in Guanacaste. 
              We'll complete a 20 kilometer journey floating along on a very calm river of 
              clear water decorated with many kinds of beautiful vegetation. With such great 
              conditions, we provide a 100% chance of catches. Though any bass loves to be 
              protected within any kind of vegetation or submerged structure, they'll go out to 
              ambush their prey, such as small frogs and crickets, at the surface. 
              Thus giving us an incredible show when using flies or artificial lures!!!! 
              we can catch other kinds fish like jaguar bass , others mojarras, tilapia and 
              snooks too. This tour has a duration of approximately 6 to 7 hours where you'll 
              also get to observe countless birds and reptiles as well as the native vegetation 
              in the area. For this type of fishing, the equipment that can be used is between 
              7 -9 wt with floating and sinking lines as they have managed to hook up to 12 lb 
              with good fishing conditions. On this trip we have unsurpassed amenities around 
              the river such as bathrooms and even changing rooms. This makes for an even mix 
              of the wild and convenience for customers!
            </span>
          )}
        </p>
        <button className="toggle-button" onClick={handleToggle}>
          {expanded? (
            <i className="fas fa-chevron-up" />
          ) : (
            <i className="fas fa-chevron-down" />
          )}
        </button>
        </div>
     <div className='contendorimagenes'>
        <img className='imagenes' src={images[image].path} alt={images[image].alt}></img>
     </div>
        
    </div>
  );
}

export default Contenedor2;