import React from 'react';
import '../estilo/Menu_bar.css'
function MenuBar() {
    return(
      <header className='barraprincipal'>
        <div className='logo'>
        <img className='logo-imagen' src={require('../imagenes/logo.png')} alt='logo'/>
        </div>     
      <nav>
        <p class='menulinks'>Costa Rica Guanacaste Fly Fishing</p>
      </nav>
      </header>
    );
}

export default MenuBar;

